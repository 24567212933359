<template>
    <div class="culture">
        <b-jumbotron lead="企业文化">
            <b-row>
        <!-- 左侧文本列 -->
        <b-col lg="2">
          
        </b-col>
        <b-col lg="4" class="d-flex flex-column justify-content-center">
            <p class="jumbotron-title jumbotron-title1"><b-avatar variant="light" class="mr-2" :src="require('@/assets/culture/icon-patient.png')"></b-avatar>患者第一</p>
            <!-- <p class="jumbotron-text">患者的需求和福祉优先级最高。</p> -->
            <p class="jumbotron-title jumbotron-title2"><b-avatar variant="light" class="mr-2" :src="require('@/assets/culture/icon-product.png')"></b-avatar>产品卓越</p>
            <!-- <p class="jumbotron-text">公司致力于开发和提供拥有卓越品质和性能的医疗器械产品，不断投入研发，以确保产品在技术、安全性和效用方面达到最高标准。</p> -->
            <p class="jumbotron-title jumbotron-title3"><b-avatar variant="light" class="mr-2" :src="require('@/assets/culture/icon-partnership.png')"></b-avatar>合众共赢</p>
            <!-- <p class="jumbotron-text">公司致力于建立合作伙伴关系，并追求共同价值。通过合作，共同开发创新的解决方案，提高医疗服务的质量和效率。</p> -->
            
        </b-col>
        
        <!-- 右侧图片列 -->
        <b-col lg="4" class="d-flex flex-column justify-content-center">
          <img src="@/assets/corp-culture.png" alt="Your Image" class="img-fluid">
        </b-col>
        <b-col lg="2">
          
        </b-col>
      </b-row>
          
  <!--        <b-button variant="outline-primary" to="/products">查看更多</b-button>-->
        </b-jumbotron>
    </div>
  </template>
  
  <script>
  export default {
  name: "Culture",
    data:function () {
      return {
      }
    }
  }
  </script>
  
  <style scoped>
  .culture .jumbotron{
    background-color: transparent;
    padding:0rem 0;
  }
  .culture{
    margin:1rem auto;
    text-align: left;
    color:rgb(2, 65, 139);
  
  }
  
  .culture .lead{
    font-size:2.5rem;
  }
  
  .culture .jumbotron-text{
    color:#504366;
  }

  .culture .jumbotron-title {
    text-align: center;
    font-size:24px;
    padding:0.5em;
  }

  .culture .jumbotron-title1{
    color:#02418B;
  }

  .culture .jumbotron-title2{
    color:#0B59B1;
  }

  .culture .jumbotron-title3{
    color:#248DBB;
  }

  @media (min-width: 1200px) {
    .culture{
      width:80%;
    }
  }
  </style>