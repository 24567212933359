<template>
  <div class="view-products container pb-3">
    <Breadcrumb title="产品中心"></Breadcrumb>
    <ProductHead></ProductHead>
    <ProductLine 
      v-for="product in sortedProducts" 
      :product="product" :key="product.title"></ProductLine>
  </div>
</template>

<script>
import ComingSoon from "@/components/common/ComingSoon";
import Breadcrumb from "@/components/common/Breadcrumb";
import ProductHead from "@/components/product/ProductHead";
import ProductLine from "@/components/product/ProductLine";
import { productList } from "@/constant/index";
export default {
name: "Product",
  components: {Breadcrumb, ComingSoon, ProductLine, ProductHead},
  data:function () {
    return {
      products: []
    }
  },computed:{
    sortedProducts(){
      const value = productList.lifesupport.filter(value=>!value.isComing).concat(productList.heartlung.filter(value=>!value.isComing)).concat(productList.lifesupport.filter(value=>value.isComing)).concat(productList.heartlung.filter(value=>value.isComing));
      console.log(value);
      return value;
    }
  }
}
</script>

<style scoped>

</style>