<template>
  <b-container class="pb-3">
    <Breadcrumb title="产品中心" url="/products" :subtitles="[{text:title}]"></Breadcrumb>
    <ProductHead></ProductHead>
    <ProductLine 
      v-for="product in products" 
      :product="product" :key="product.title"></ProductLine>
  </b-container>
</template>

<script>
import ComingSoon from "@/components/common/ComingSoon";
import Breadcrumb from "@/components/common/Breadcrumb";
import ProductLine from "@/components/product/ProductLine";
import { productMenu, productList } from "@/constant/index";
import ProductHead from "@/components/product/ProductHead";
export default {
  name: "HeartLung",
  components: {Breadcrumb, ComingSoon, ProductLine, ProductHead},
  data:function(){
    console.log(productMenu);
    const title = productMenu[1].title;
    return {
      title:title,
      products:productList.heartlung
    }
  }
}
</script>

<style scoped>

</style>