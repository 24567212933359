<template>
    <b-container>
      <Breadcrumb 
            title="产品中心" 
            url="/products" 
            :subtitles="[{text:'重症生命支持', url:'/icu'}, {text:'STM CP-24 I型 离心泵泵头'}]">
        </Breadcrumb>
        <b-row class="py-5">
            <b-col md="6">
                <img :src="require('@/assets/ecmo/pump-head.png')" class="img-fluid">
            </b-col>
            <b-col class="d-flex flex-row align-items-center" md="6">
                <div>
                    <div class="text-left h2 my-4" style="font-weight:bold">STM CP-24 I型 离心泵泵头</div>
                    <div class="text-left h6 my-3" style="color:#aaa;">与OASSIST ECMO系统配套使用</div>   
                    <div class="text-right mt-5 mr-3">
                        <a href="/download/OASSIST ECMO系统彩页.pdf" target="_blank" class="btn btn-primary">下载彩页</a>
                    </div>      
                </div>         
            </b-col>
        </b-row>
        <div class="section-high-effciency my-5 py-5">
          <b-row class="mt-5">
              <b-col class="text-left">
                  <div class="h1" style="color:rgb(2, 65, 139);letter-spacing:0.3rem;font-weight: bold;">高效血流输出</div>
                  <p class="body-text" style="letter-spacing:0.2rem;">综合并优化叶轮、蜗壳等设计特点，实现输出最大8L/min及最高670mmHg压力</p>
              </b-col>
          </b-row>
          <b-row>
              <b-col md="7">
                <div style="width:90%;margin:0 auto;">
                  <img class="img-fluid" :src="require('@/assets/ecmo/pump-performance.png')">
                </div>
              </b-col>
              <b-col class="d-flex flex-row align-items-end" md="5">      
                <div class="mt-3 p-3">
                  <p class="text-justify instruction-text" style="letter-spacing:0.1rem;">测试说明：</p>
                  <p class="text-justify instruction-text" style="letter-spacing:0.1rem;">试验液为模拟血液：0.9%浓度的NaCl溶液和甘油混合液，NaCl溶液与甘油比例为2.3:1，粘度为2.0×10<sup>-3</sup> Pa·S~3.5×10<sup>-3</sup> Pa·S之间</p>
                  <p class="text-justify instruction-text" style="letter-spacing:0.1rem;">在37°C±1°C下，将离心泵泵头置入合适的测试回路中。通过设置离心泵泵头不同转速 (2000RPM、3000RPM、4000RPM、5500RPM) 及流量 (0.0 L/min、2.0
                  L/min、4.0 L/min、6.0 L/min、8.0 L/min)，测量人口与出口间的压差</p>
                  <p class="text-justify instruction-text" style="letter-spacing:0.1rem;">泵头转速误差±50 RPM，流量误差±0.05L/min，压力误差±10%</p>
                </div>                          
              </b-col>
          </b-row>
        </div>
        <div class="section-low-damage my-5 py-5">
          <b-row class="mt-5">
              <b-col class="text-left">
                  <div class="h1" style="color:rgb(2, 65, 139);letter-spacing:0.3rem;font-weight: bold;">减少血流破坏</div>
                  <p class="body-text" style="letter-spacing:0.2rem;">降低预充、避免血液瘀滞及返流流道，实现同流量下血液停留时间更短，血液破坏更低</p>
              </b-col>
          </b-row>
          <b-row>
              <b-col md="5">
                <div style="width:90%;margin:0 auto;" class="text-left">
                  <img class="img-fluid" :src="require('@/assets/ecmo/pump-simu1.png')">
                  <ul style="width:90%;margin:1rem auto;">
                    <li>无血液瘀滞区，避免血栓形成</li>
                    <li>无回流区域，减少血液破坏</li>
                  </ul>
                </div>
              </b-col>
              <b-col md="5">
                <div style="width:90%;margin:0 auto;" class="text-left">
                  <img class="img-fluid" :src="require('@/assets/ecmo/pump-simu2.png')">
                  <ul style="width:90%;margin:1rem auto;">
                    <li>泵体，尤其是转子周边，无高剪切力区间，降低溶血发生</li>
                  </ul>
                </div>
              </b-col>
              <b-col class="d-flex flex-row align-items-end" md="2">      
                <div class="p-2">
                  <p class="text-left instruction-text" style="letter-spacing:0.1rem;">离心泵工况</p>
                  <p class="text-left instruction-text" style="letter-spacing:0.1rem;">流量:5LPM</p>
                  <p class="text-left instruction-text" style="letter-spacing:0.1rem;">转速: 3000 RPM</p>
                </div>                          
              </b-col>
          </b-row>
        </div>

        <div class="section-tech-param my-5 py-5">
          <b-row class="mt-5">
              <b-col class="text-left">
                  <div class="h1" style="color:rgb(2, 65, 139);letter-spacing:0.3rem;font-weight: bold;">技术参数</div>
              </b-col>
          </b-row>
          <b-row>
              <b-col md="4">
              </b-col>
              <b-col md="4">
                   <div class="p-2">
                    <b-table 
                      stacked hover
                      :items="[{'预 充 量':'24毫升','转速区间':'0~5500转/分', '流量范围':'0~8升/分', '接口尺寸':'3/8英寸'}]" 
                      style="border:1px solid #ddd;font-size:1.2rem;">
                    </b-table>
                    <!-- <b-row style="background-color: rgb(2, 65, 139); color:white;font-weight: bold;letter-spacing: 0.2rem;font-size:1.2rem;" class="p-3">
                      <b-col> STM CP-24 I型离心泵泵头</b-col>
                    </b-row>
                    <b-row style="background-color: #ddd;">
                      <b-col class="table-cell"> 预 充 量</b-col>
                      <b-col class="table-cell"> 24毫升</b-col>
                    </b-row>
                    <b-row style="background-color: #aaa;">
                      <b-col class="table-cell"> 转速区间</b-col>
                      <b-col class="table-cell"> 0~5500转/分</b-col>
                    </b-row>
                    <b-row style="background-color: #ddd;">
                      <b-col class="table-cell"> 流量范围</b-col>
                      <b-col class="table-cell"> 0~8升/分</b-col>
                    </b-row>
                    <b-row style="background-color: #aaa;">
                      <b-col class="table-cell"> 接口尺寸</b-col>
                      <b-col class="table-cell"> 3/8英寸</b-col>
                    </b-row> -->
                   </div>
              </b-col>
              <b-col md="4">
              </b-col>
          </b-row>
        </div>
    </b-container>
</template>
<style>
  .key-word{
      font-size:3.5rem;
  }

  .title-text{
      font-size:2rem!important;letter-spacing:0.3rem; font-weight: bold!important;
  }

  .body-text{
      font-size:1.2rem;
  }

  .instruction-text{
    font-size:0.85rem;
    color:#999;
    margin:0;
  }

  .section-low-damage li, .section-low-damage ul{
    padding: 0;
    margin:0;    
  }

  .table-cell{
    border:1px solid white;
    margin:0;
    padding:0.5rem;
    font-size:1.2rem;
  }
</style>
<script>
import Breadcrumb from "@/components/common/Breadcrumb";
export default {
  name: "CP24",
  components:{Breadcrumb},
  data:function(){
    return {
      items:[
        {}
      ]
    };
  }
}
</script>