<template>
  <div class="main-jumbotron container">
    <b-card
        overlay
        :img-src="require('@/assets/home/jumbotron-covered-cut-exported.jpg')"
        img-alt="Card Image"
        text-variant="white"
    >
      <div class="container slogan-container">
        <h1>专注生命支持医疗科技</h1>
        <h4>A life support technology company</h4>
      </div>
    </b-card>
<!--    <div class="container">-->
<!--      <b-jumbotron>-->
<!--        <template #header>专注生命支持科技</template>-->

<!--        <template #lead>-->
<!--          Science Technology-->
<!--        </template>-->

<!--&lt;!&ndash;        <hr class="my-4">&ndash;&gt;-->

<!--&lt;!&ndash;        <p>&ndash;&gt;-->
<!--&lt;!&ndash;          It uses utility classes for typography and spacing to space content out within the larger&ndash;&gt;-->
<!--&lt;!&ndash;          container.&ndash;&gt;-->
<!--&lt;!&ndash;        </p>&ndash;&gt;-->

<!--        <b-button variant="primary" href="#">查看更多</b-button>-->
<!--&lt;!&ndash;        <b-button variant="success" href="#">Do Something Else</b-button>&ndash;&gt;-->
<!--      </b-jumbotron>-->
<!--    </div>-->
  </div>
</template>

<script>
export default {
name: "Jumbotron"
}
</script>

<style scoped>
.main-jumbotron{
  /*background-color: #7abaff;*/
  text-align: left;
  margin:1rem auto;
  padding:0;
}

.main-jumbotron .jumbotron{
  background-color: transparent;
}

.card-img-overlay{
  width:90%;
  margin:0 auto;
}

.slogan-container{
  display:flex;
  flex-direction: column;
  justify-content: center;
  height:100%;
  color:rgb(2, 65, 139);
}

@media (min-width: 1201px) {
  .slogan-container h1{
    font-size:4rem;
  }

  .slogan-container h4{
    font-size:2rem;
  }
}

@media (max-width: 1200px) {
  .slogan-container h1{
    font-size:2.5rem;
  }

  .slogan-container h4{
    font-size:1rem;
  }
}

@media (max-width: 756px) {
  .slogan-container h1{
    font-size:1.2rem;
  }

  .slogan-container h4{
    font-size:0.7rem;
  }
}

.slogan-container h1{
  color: white;
}

.slogan-container h4{
  color: white;
  text-indent: 1rem;
}
</style>