<template>
<div class="footer p-3">
<b-container style="width:100%;max-width:500px;color:white;">
  <b-row>
    <b-col cols="3"></b-col>
    <b-col cols="2" style="border-right:1px solid white;"><a href="/termofuse">使用条款</a></b-col>
    <b-col cols="2" style="border-right:1px solid white;"><a href="/legalstatement">法律声明</a></b-col>
    <b-col cols="2"><a href="/contact">联系我们</a></b-col>
    <b-col cols="3"></b-col>
  </b-row>
  <b-row class="mt-3">
    <b-col></b-col>
    <b-col cols="8" style="font-size:14px;"><a href="https://beian.miit.gov.cn/">苏ICP备2021015778号</a></b-col>
    <b-col></b-col>
  </b-row>
  <b-row class="mt-1">
    <b-col></b-col>
    <b-col cols="8" style="font-size:14px;">©2021 江苏赛腾医疗科技有限公司</b-col>
    <b-col></b-col>
  </b-row>

</b-container>
</div>
</template>

<script>
export default {
name: "Footer"
}
</script>

<style scoped>
.footer {
  width:100%;;
  background-color: rgb(2, 65, 139);
  height:auto;
}
.footer ul{
  padding:0;
}

.footer a{
  color:white;font-size:0.8rem;line-height:0.8rem;
}
</style>