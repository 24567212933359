<template>
  <div class="jumbotron jumbotron-fluid">
    <div class="container">
      <p class="p-0 mb-0" style="font-size:1.5rem;font-weight: lighter"><em>“我们为{{target}}提供系列产品和解决方案”</em></p>
    </div>
  </div>
</template>

<script>
import { productMenu } from "@/constant/index";
export default {
  name: "ProductHead",
  data:function(){
    return {
      target:`${productMenu[1].title}及${productMenu[0].title}`
    }
  }
}
</script>

<style scoped>
.jumbotron{
  padding:2rem;
  background-color:#07B;
  color:white;
  margin-bottom:0px;
}
</style>