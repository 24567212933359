import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Products from "@/views/Products";
import Physicians from "@/views/Physicians";
import Contact from "@/views/Contact";
import About from "@/views/About";
import Recruiting from "@/views/Recuiting";
import ComingSoon from "@/views/Dummy";
import LifeSupport from "@/views/LifeSupport";
import HeartLung from "@/views/HeartLung";
import ECMO from "@/views/ECMO";
import CP24I from "@/views/CP24I";
import FM01 from "@/views/FM01";
import Legal from "@/views/Legal";
import TermOfUse from "@/views/TermOfUse";
import HCSystem from "@/views/HCSystem";

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    // component: Home
    component: Home
  },{
    path: '/products',
    name: 'Products',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Products
  },{
    path: '/physicians',
    name: 'Physicians',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Physicians
  },{
    path: '/contact',
    name: 'Contact',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Contact
  },{
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: About
  },{
    path: '/articlelist',
    name: 'ArticleList',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ComingSoon
  },{
    path: '/recruiting',
    name: 'Recruiting',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Recruiting
  },{
  path: '/icu',
  name: 'LifeSupport',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: LifeSupport
},{
  path: '/cardiac',
  name: 'HeartLung',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: HeartLung
},{
  path: '/ecmo',
  name: 'ECMO',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: ECMO
},{
  path: '/stm-cp-24',
  name: 'CP24I',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: CP24I
},{
  path: '/fm01',
  name: 'FM01',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: FM01
},{
  path: '/hcsystem',
  name: 'HCSystem',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: HCSystem
},{
  path: '/termofuse',
  name: 'TermOfUse',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: TermOfUse
},{
  path: '/legalstatement',
  name: 'LegalStatement',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: Legal
}]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})

export default router
