<template>
<div class="view-coming-soon">
  <h1 style="color: rgb(2, 65, 139);">
    敬请期待
  </h1>

</div>
</template>

<script>
export default {
name: "ComingSoon"
}
</script>

<style scoped>
.view-coming-soon{
  height:100%;
  width:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height:20rem;

}

</style>