<template>
    <div class="technique">
        <h1 class="text-left mt-5" style="color:rgb(2, 65, 139);">核心技术平台</h1>
        <h5 class="text-left my-3" style="color:#666;">围绕研发生命支持器械的需求，赛腾建立了扎实的核心技术平台</h5>
        <b-row>
            <b-col v-for="(ele, i) in dataList" :key="ele.title" class="p-0" lg>
                <b-card class="custom-card">
                    <!-- Title -->
                    <div class="card-title">
                        <div class="card-title-text">{{ele.title }}</div>
                    </div>
                    
                    <!-- Body -->
                    <div class="card-body" :class="{'dashed-right-border':i < dataList.length - 1}">
                        <p class="tech-desc-text">{{ ele.text }}</p>
                    </div>
                    
                    <!-- Image -->
                    <div class="card-image">
                        <b-img :src="ele.imgSrc" fluid :alt="ele.title"></b-img>
                    </div>
                </b-card>
            </b-col>
        </b-row>
    </div>
  </template>
  
  <script>
  export default {
  name: "Technique",
    data:function () {
      return {
        dataList:[
            {"title":"高可靠电子设计", "text":"集成化、低功耗、多重备份设计，带来更小的系统体积，且即使在极端情况下仍然能维持稳定运行。", "imgSrc":require("@/assets/tech/tech5.png")},
            {"title":"流体仿真及设计", "text":"仿真结合实验不断优化设计，流阻压降等指标达到行业金标准，并且消除凝滞流场带来更佳的防血栓性能。", "imgSrc":require("@/assets/tech/tech4.png")},
            {"title":"表面涂层技术", "text":"通过专利的涂层制备方法，实现更牢固均匀的涂层涂覆能力，实现长效抗血栓效果。", "imgSrc":require("@/assets/tech/tech1.png")},
            {"title":"高分子材料工艺", "text":"独家多层高分子材料复合工艺，支持超薄且高韧性的生命支持耗材研发。", "imgSrc":require("@/assets/tech/tech2.png")},
            // {"title":"团队力量", "text":"不迷信权威，不盲目仿造；吃透底层技术后，结合并满足临床需求，提出独特的产品解决方案。", "imgSrc":require("@/assets/tech/tech3.png")},
        ]
      }
    }
  }
  </script>
  
  <style scoped>
  .card-body{
    padding:0px;
    background-color:#F2F2F2;
  }

  .dashed-right-border{
    border-right:1px dashed #999;
  }
  .card-title{
    /* background:linear-gradient(rgba(255, 255, 255, 0) 50%, rgba(0, 0, 0, 0) 50%); */
    padding:15px;
    background:linear-gradient(white 50%, #F2F2F2 50%);
  }

  .tech-desc-text{
    font-size:12px;
    text-align: justify;
    padding-left: 1em;
    padding-right:1em;
    min-height:6em;
    color:#504366;
  }

  .card-title-text{
    display: inline-block;
    color:white;
    padding:8px;
    border-radius:5px;
    background-color:#aaa;
  }

  .card-image{
    padding:20px 10px;
  }
  @media (min-width: 1200px) {
    .technique{
      width:80%;
      margin:0 auto;
      padding:2rem 0;
    }
  }
  </style>