<template>
  <div class="coming-soon container">
    <Breadcrumb title="敬请期待"></Breadcrumb>
    <ComingSoon></ComingSoon>
  </div>
</template>

<script>
import ComingSoon from "@/components/common/ComingSoon";
import Breadcrumb from "@/components/common/Breadcrumb";
export default {
name: "Dummy",
  components: {Breadcrumb, ComingSoon},
  data:function () {
    return {
    }
  }
}
</script>

<style scoped>

</style>