<template>
<div class="container menu-display">
  <b-row>
    <b-col v-for="item in menuList" :key="item.id" lg="3" class="menu-display-card">
      <b-card
          :title="item.title"
          tag="article"
          style="background-color: rgb(0, 153, 212);height:100%;"
          class="mb-2"
      >
        <b-card-text v-if="item.showText">
          <div v-for="txt in item.intro" :key="txt">
            {{txt}}
          </div>
        </b-card-text>
        <div style="text-align: right;">
          <b-button v-if="item.showBtn" :to="item.url ? item.url : '#' " variant="outline-light">查看更多</b-button>
        </div>
      </b-card>
    </b-col>
  </b-row>
</div>
</template>

<script>
export default {
name: "MenuDisplay",
  data:function(){
    return {
      menuList:[
        {id:1, title:'关于赛腾', url:'/about', intro:['了解赛腾'],  showBtn: true, showText:false},
        {id:2, title:'产品中心', url:'/products', intro:['浏览赛腾产品'],  showBtn: true, showText:false},
        {id:3, title:'招贤纳士', url:'/recruiting', intro:['加入赛腾'], showBtn: true, showText:false},
        {id:4, title:'联系我们', intro:[
            '电话:+86 0512-6788 6680',
            '传真:+86 0512-6788 6680',
            '邮箱:contact@st-med.com',
            '地址:江苏省苏州市苏州工业园区东富路9号东景工业坊36幢',
            '邮编:215123'
          ], showBtn:true, showText: false, url:'/contact'
        }
      ]
    }
  }
}
</script>

<style scoped>
.menu-display .card{
  text-align: left;
}

.menu-display .card-title{
  color:white;
  font-size:1.2rem;
  margin-bottom:1.5rem;
}

.menu-display .card-text{
  color:white;
  font-size:0.8rem;
}

.menu-display .card-body{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 1200px) {
  .menu-display-card{
    padding:0.5rem;
  }
  .menu-display .row{
    padding:0 0.5rem;
  }
}

@media (min-width: 1201px){
  .menu-display-card{
    padding:0.5rem;
  }
}
</style>