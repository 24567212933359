<template>
  <div class="about container">
    <Breadcrumb title="关于赛腾"></Breadcrumb>
    <Intro></Intro>
    <!-- <culture-new></culture-new> -->
    <culture></culture>
    <IntellectualProperty></IntellectualProperty>
    <technique></technique>
    <history-new class="my-5"></history-new>
    <!-- <environment class="my-5"></environment> -->
    <!-- <historynew class="my-5"></historynew> -->
  </div>
</template>

<script>
import Intro from "@/components/about/Intro";
import Breadcrumb from "@/components/common/Breadcrumb";
import Culture from '../components/about/Culture.vue';
import CultureNew from "../components/about/CultureNew.vue";
import HistoryNew from '../components/about/HistoryNew.vue';
import Environment from '../components/about/Environment.vue';
import IntellectualProperty from '../components/about/IntellectualProperty.vue';
import Technique from '../components/about/Technique.vue';
export default {
  name: "About",
  components: {Breadcrumb, Intro, Culture, HistoryNew, Environment, IntellectualProperty, Technique, CultureNew}
}
</script>