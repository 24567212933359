<template>
    <b-container>
        <Breadcrumb 
            title="产品中心" 
            url="/products" 
            :subtitles="[{text:'重症生命支持', url:'/icu'}, {text:'OASSIST<sup>®</sup> 体外心肺支持辅助设备'}]">
        </Breadcrumb>
        <b-row class="py-5">
            <b-col md="6">
                <img :src="require('@/assets/ecmo/ecmo-head.png')" class="img-fluid">
            </b-col>
            <b-col class="d-flex flex-row align-items-center" md="6">
                <div>
                    <div class="text-left h2 my-4" style="font-weight:bold">OASSIST<sup>®</sup> 体外心肺支持辅助设备</div>
                    <div class="text-left h6 my-3" style="color:#aaa;">便携式ECMO系统</div>    
                    <div class="text-right mt-5 mr-3">
                        <a href="/download/OASSIST ECMO系统彩页.pdf" target="_blank" class="btn btn-primary">下载彩页</a>
                    </div>   
                </div>         
            </b-col>
        </b-row>
        <div class="section-ling py-5">
            <b-row class="mt-5">
                <b-col>
                    <div class="card mb-3">
                        <div class="row g-0">
                            <div class="col-md-7">
                                <div class="card-body">
                                    <h5 class="card-title text-left h1" style="color:rgb(2, 65, 139);letter-spacing:0.3rem;font-weight: bold;">
                                        <span class="key-word">灵</span>活选择 行<span class="key-word">动</span>自如</h5>
                                    <p class="card-text text-justify" style="font-size:1.2rem;">OASSIST<sup>®</sup> ECMO系统兼顾床旁及转运等多种使用场景，创新设计的便携式泵头驱动器，是一款可长时间独立工作的驱动器，为ECMO技术开展提供更多可能。</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <b-row class="py-5 bg-separator-1">
                <b-col md="4">
                <img :src="require('@/assets/ecmo/p3-img1.png')" class="img-fluid rounded-start" alt="...">
                </b-col>
                <b-col md="1">  
                </b-col>
                <b-col md="7">   
                    <div class="card-body">
                        <h5 class="card-title text-left h1 title-text">
                            床旁模式</h5>
                        <p class="card-text text-justify body-text">控制台与驱动器联合使用，监测功能全面，为ECMO患者提供全面的心肺功能支持。</p>
                    </div>
                    <div class="row">
                        <b-col md="1"></b-col>
                        <b-col md="4">   
                        <img class="img-fluid" :src="require('@/assets/ecmo/console-no-bg.png')">
                        </b-col>
                        <b-col md="1"></b-col>
                        <b-col md="4">   
                        <img class="img-fluid" :src="require('@/assets/ecmo/pump-no-bg.png')">
                        </b-col>
                    </div>
                </b-col>
            </b-row>       
            <b-row class="mb-5 py-5 bg-separator-2">
                <b-col md="7">
                    <div class="card-body">
                        <h5 class="card-title text-left h1 title-text">便携模式</h5>
                        <p class="card-text text-justify body-text">泵头驱动器独立工作，配合直流电源长时间使用，可作为院内转运及长距离运输的首选。</p>
                    </div>
                    <b-row>
                        <b-col md="2"> </b-col>
                        <b-col md="4">   
                        <img class="img-fluid" :src="require('@/assets/ecmo/pump-no-bg.png')">
                        </b-col>
                        <b-col md="4">   
                        <img class="img-fluid" :src="require('@/assets/ecmo/pw-src-no-bg-sm.png')">
                        </b-col>
                    </b-row>
                </b-col>
                <b-col md="1">  
                </b-col>
                <b-col class="d-flex align-items-end" md="4">
                    <img :src="require('@/assets/ecmo/p3-img2.png')" class="img-fluid rounded-start" alt="...">
                </b-col>
            </b-row>
            
        </div>
        <div class="section-zhi py-5">
            <b-row class="mt-5">
                <b-col class="col-md-1">
                    <div class="key-word" style="color:rgb(2, 65, 139);letter-spacing:0.3rem;font-weight: bold;">智</div>
                </b-col>
                <b-col class="d-flex col-md-10 text-left align-items-end">
                    <span class="mb-3 title-text">为患者提供合适的安全支持</span>
                </b-col>
            </b-row>
            <b-row class="my-3">
            <div class="col-md-1">

            </div>
            <div class="col-md-4 px-5 py-3">
                <div class="mb-5">
                    <img :src="require('@/assets/ecmo/console-front.png')" class="img-fluid rounded-start" alt="...">
                </div>
                <div class="mt-5">
                    <img :src="require('@/assets/ecmo/console-back.png')" class="img-fluid rounded-start" alt="...">
                </div>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-6 d-flex flex-column justify-content-center">
                <div class="card-body">
                    <h5 class="card-title text-left mb-3 title-text">监测功能全面</h5>
                    <p class="card-text text-justify my-2 body-text">流量/气泡 × 2</p>
                    <p class="card-text text-justify my-2 body-text">压力监测 × 4</p>
                    <p class="card-text text-justify my-2 body-text">温度监测 × 2</p>
                </div>
                <div class="card-body">
                    <h5 class="card-title text-left mb-3 title-text">独特的第二流量端口设计</h5>
                    <p class="card-text text-justify my-2 body-text">实现系统内对VVA及VAV等带分支</p>
                    <p class="card-text text-justify my-2 body-text">回路ECMO模式管理</p>
                </div>
                <div class="card-body">
                    <h5 class="card-title text-left my-3 title-text">安全性全面提升</h5>
                    <p class="card-text text-justify my-2 body-text">零流量模式及气泡干预模式</p>
                    <p class="card-text text-justify my-2 body-text">3种警告级别，警报列表快速一览</p>
                </div>
            </div>            
        </b-row>

        </div>
        <div class="section-jian py-5">
            <b-row class="mt-5">
                <b-col class="col-md-1">
                    <div class="key-word" style="color:rgb(2, 65, 139);letter-spacing:0.3rem;font-weight: bold;">简</div>
                </b-col>
                <b-col class="d-flex col-md-10 text-left align-items-end">
                    <span class="mb-3 title-text">让ECMO技术更易于开展</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <div class="card mb-3">
                        <div class="row g-0">
                            <div class="col-md-1"></div>
                            <div class="col-md-4 p-5">
                                <img :src="require('@/assets/ecmo/pump-front.png')" class="img-fluid rounded-start" alt="...">
                            </div>
                            <div class="col-md-1"></div>
                            <div class="col-md-6 d-flex align-items-center">
                                <div class="card-body">
                                    <h5 class="card-title text-left mb-3 title-text">首款便携式驱动泵</h5>
                                    <p class="card-text text-justify my-2 body-text">仅重<span style="font-size:2rem;color:rgb(2, 65, 139);font-weight:bold;">4千克</span>，<span style="font-size:2rem;color:rgb(2, 65, 139);font-weight:bold;">3小时</span>持久续航。</p>
                                    <p class="card-text text-justify my-2 body-text">提供核心的血流驱动及监测功能。</p>
                                    <p class="card-text text-justify my-2 body-text">可替换电池或配合直流电源，实现长时间独立使用。</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </div>       
    </b-container>
</template>
<style>
.card {
    border:none!important;
}

.key-word{
    font-size:3.5rem;
}

.title-text{
    font-size:2rem!important;letter-spacing:0.3rem; font-weight: bold!important;
}

.body-text{
    font-size:1.2rem;
}

.bg-separator-1{
    background: linear-gradient(to right, #fff, #eee 75%, #fff)!important;
}
.bg-separator-2{
    background: linear-gradient(to right, #fff, #eee 25%, #fff)!important;
}
</style>
<script>
import Breadcrumb from "@/components/common/Breadcrumb";
export default {    
  name: "ECMO",
  components:{Breadcrumb},
  data:function(){
    return {
        
    };
  }
}
</script>