<template>
    <b-container>
      <Breadcrumb 
            title="产品中心" 
            url="/products" 
            :subtitles="[{text:'重症生命支持', url:'/icu'}, {text:'STM HC 01 热交换水箱'}]">
        </Breadcrumb>
        <b-row class="py-5">
            <b-col md="6">
                <img :src="require('@/assets/products/hcsystem-w512.png')" class="img-fluid">
            </b-col>
            <b-col class="d-flex flex-row align-items-center" md="6">
                <div>
                    <div class="text-left h2 my-4" style="font-weight:bold">STM HC 01 热交换水箱                    </div>
                    <div class="text-left h6 my-3" style="color:#aaa;">用于体外循环心脏支持手术中或术后对患者进行升温或降温调节</div>   
                    <div class="text-right mt-5 mr-3">
                        <a href="/download/STM HC 01 热交换水箱-彩页.pdf" target="_blank" class="btn btn-primary">下载彩页</a>
                    </div>      
                </div>         
            </b-col>
        </b-row>
        <b-row class="text-left">
            <b-col md="5">
                <div class="h1" style="color:rgb(2, 65, 139);letter-spacing:0.3rem;font-weight: bold;margin-bottom: 1rem;">产品特点</div>
                <div style="width:100%;margin:0 auto;" class="text-left" v-for="f in features" :key="f.title">
                  <div class="feature-title py-1" v-text="f.title"></div>
                  <div class="feature-desc p-2" v-text="f.desc"></div>
                </div>
            </b-col>
            <b-col md="7">
                <div class="container">
                    <div class="h1" style="color:rgb(2, 65, 139);letter-spacing:0.3rem;font-weight: bold;margin-bottom: 1rem;">设备信息</div>
                    <b-table 
                      stacked
                      :items="[{'尺寸(W×H×D)':'530mm×331mm×380mm 允差±5mm','功耗':'1000 VA', '交流电源':'a.c.100~240V，50/60Hz', '显示屏':'8.0″ LCD触摸屏', '接口':'水管接口×2<br>USB接口×1<br>交流电源接口×1<br>等电位端子×1', '电池':'1个锂离子电池10.8V / 7000 mAh'}]" 
                      style="border:1px solid #ddd;font-size:1.2rem;">
                      <template #cell()="{ value }">
                        <span v-html="value"></span>
                      </template>
                    </b-table>
                </div>                
            </b-col>
        </b-row>

        <div class="section-tech-param my-5 py-5">
          <b-row class="my-3">
              <b-col class="text-center">
                  <div class="h1" style="color:rgb(2, 65, 139);letter-spacing:0.3rem;font-weight: bold;">技术参数</div>
              </b-col>
          </b-row>
          <b-row class="text-left">
              <b-col md="1">
              </b-col>
              <b-col md="10">
                <b-table 
                      stacked
                      :items="[{'温度控制范围':'15°C ~ 41°C 允许误差±0.3°C','显示范围':'10°C ~ 45°C 其中15°C～41°C范围内显示误差不大于±0.3°C', '加热速率':'2.2°C/min (15 °C 至 41°C)', 
                      '冷却速率':'0.9°C/min (41°C 至 15 °C)', '流量':'空载流量最大值不低于15L/min', '管路压力':'最大150kPa', '水箱容量':'0.85L~1.23L，允差±50ml'}]" 
                      style="border:1px solid #ddd;font-size:1.2rem;">
                      <template #cell()="{ value }">
                        <span v-html="value"></span>
                      </template>
                    </b-table>                    
              </b-col>
              <b-col md="1">
              </b-col>
          </b-row>
        </div>


    </b-container>
</template>
<style>
  .key-word{
      font-size:3.5rem;
  }

  .title-text{
      font-size:2rem!important;letter-spacing:0.3rem; font-weight: bold!important;
  }

  .body-text{
      font-size:1.2rem;
  }

  .instruction-text{
    font-size:0.85rem;
    color:#999;
    margin:0;
  }

  .table-cell{
    border:1px solid white;
    margin:0;
    padding:0.5rem;
    font-size:1.2rem;
  }

  .feature-title{
    color:white;
    background-color:#2A609E;
    width:30%;
    border-radius:1rem;
    text-align: center;
    font-size:1.2rem;
    margin-bottom:0.5rem;
  }

  .feature-desc{    
    border-top:1px dashed #ccc;
    margin-bottom:1rem;
  }
  .feature-desc::before {
        content: '\2022'; /* Unicode字符代表实心圆点 */
        display: inline-block; 
        transform: translateX(-1em); /* 使用transform属性向左移动一个字符宽度，从而实现缩进 */
    }

    .bd-right{
        border-right:1px solid #ccc;
    }

    .bd-bottom{
        border-bottom: 1px solid #ccc;
    }

    .normal-font{
        font-size:1.2rem;
    }

    .table_head{
        background-color:blue;
    }
</style>
<script>
import Breadcrumb from "@/components/common/Breadcrumb";
export default {
  name: "FM01",
  components:{Breadcrumb},
  data:function(){
    return {
      items:[
        {}
      ],
      features:[
        {'title':'精准控温', "desc":'电子控制系统可精确调节输送给患者血液的温度，确保安全，控温精度 ± 0.3°C'},
        {'title':'快速变温', "desc":"变温范围15-41°C，配合空载情况下最大值不低于15L/min的水流量，可以实现15°C-41°C时2.2°C/min的加热速率，41°C-15°C时0.9°C/min的冷却速率。"},
        {'title':'智能变温', "desc":"设定目标温度及变温速度，系统可以实现智能化连续自动变温。"},
        {'title':'操作简便', "desc":"设计简单，安全，可靠，简约直观的操作界面配备多种指示器，让设备状态一目了然。"},
      ]
    };
  }
}
</script>