<template>
  <b-breadcrumb>
    <b-breadcrumb-item v-for="item, i in items" :to="item.to" :key="i" :active="i==items.length - 1" :html="item.text">
    </b-breadcrumb-item>
  </b-breadcrumb>
</template>

<script>
export default {
  name: "Breadcrumb",
  props:['title', 'url', 'subtitles'],
  data:function(){
    const _items = [{
        text: '首页',
        to: '/'
      }];

    if(this.subtitles){
      let titleItem = {
        text: this.title
      };
      if(this.url){
        titleItem.to = this.url;
      }
      _items.push(titleItem);
      for(let i = 0; i < this.subtitles.length; i++){
        if(i == this.subtitles.length - 1){
          _items.push({
            text:this.subtitles[i].text,
            active:true
          });
        }else{
          _items.push({
            text:this.subtitles[i].text,
            to:this.subtitles[i].url
          })
        }
      }
    }else{
      _items.push({
        text:this.title,
        active:true
      })
    }

    return {
      items:_items
    }
  }
}
</script>

<style scoped>
.breadcrumb-item a{
  color: rgb(2, 65, 139);
}
</style>