<template>
  <div id="app">
    <top-nav></top-nav>
    <div style="min-height:700px;">
      <router-view/>
    </div>
    <Footer></Footer>
  </div>
</template>

<style>
#app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  font-family: 'main-font-regular', '微软雅黑', 'Microsoft Yahei';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height:800px;
}
.form-inline {
  justify-content: center!important;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.breadcrumb{
  background-color: transparent!important;
}
</style>
<script>
import TopNav from "@/components/common/TopNav";
import Footer from "@/components/common/Footer";

export default {
  components: {Footer, TopNav}
}
</script>