<template>
    <div class="recruiting container mb-3">
        <div class="my-5 text-justify" style="max-width:700px;margin:0 auto;">
        <h2 class="text-center my-4">法律声明</h2>
        <p class="text-right">最新版本生效日期：2023年3月1日</p>
        <h4>权利归属</h4>    
        <p class="p-legal">除非赛腾医疗官方网站另行声明，赛腾医疗官方网站推出的所有官方产品、技术、软件、程序、数据及其他信息（包括文字、图标、图片、照片、音频、视频、图表、色彩组合、版面设计等）的所有权利（包括著作权、商标权、专利权、商业秘密及其他相关权利）均归江苏赛腾医疗科技有限公司及/或其关联公司所有。未经江苏赛腾医疗科技有限公司及/或其关联公司事先许可，任何人擅自使用上述内容和信息，可能会侵犯江苏赛腾医疗科技有限公司及/或其关联公司的权利，我们将会追究侵权者的法律责任。如有宣传、展示等任何使用需要，请您务必取得江苏赛腾医疗科技有限公司及/或其关联公司的事先许可。</p>
        <h4>信息限制</h4>
        <p class="p-legal">为维护赛腾医疗官方网站的正常运行秩序及效率，未经江苏赛腾医疗科技有限公司及/或其关联公司许可，任何人不得自行、授权或协助第三方对赛腾医疗官方网站及其系统进行地址扫描、网络端口扫描、操作系统探测等扫描及/或探测，或以包括通过机器人、蜘蛛等程序或设备监视、复制、传播、展示、镜像、上载、下载等方式擅自获取、使用赛腾医疗官方网站内的任何内容。</p>
        <h4>知识产权保护</h4>
        <p>我们尊重知识产权，反对并打击侵犯知识产权的行为。知识产权权利人若认为赛腾医疗官方网站内容侵犯其合法权益的，可以通过联系邮箱contact@st-med.com进行投诉，我们将在收到知识产权权利人合格通知后依据相应的法律法规及时处理。</p>        
      </div>
    </div>
  </template>
  
  <script>
  
  export default {
  name: "Legal",
    components: {},
    data:function(){
      return {
      }
    }
  }
  </script>
  
  <style scoped>
.p-legal{
    margin-bottom:2rem;
}
  </style>