<template>

  <div class="view-physicians">
    <ComingSoon></ComingSoon>
  </div>
</template>

<script>
import ComingSoon from "@/components/common/ComingSoon";
export default {
name: "Physicians",
  components: {ComingSoon}
}
</script>

<style scoped>

</style>