<template>
    <b-container>
      <Breadcrumb 
            title="产品中心" 
            url="/products" 
            :subtitles="[{text:'心胸外科手术', url:'/cardiac'}, {text:'STM FM 01 医用流量计'}]">
        </Breadcrumb>
        <b-row class="py-5">
            <b-col md="6">
                <img :src="require('@/assets/products/flowmonitor-w512.png')" class="img-fluid">
            </b-col>
            <b-col class="d-flex flex-row align-items-center" md="6">
                <div>
                    <div class="text-left h2 my-4" style="font-weight:bold">STM FM 01 医用流量计</div>
                    <div class="text-left h6 my-3" style="color:#aaa;">用于体外循环手术中循环管道内血液流量的测量</div>   
                    <div class="text-right mt-5 mr-3">
                        <a href="/download/STM FM 01 医用流量计-彩页.pdf" target="_blank" class="btn btn-primary">下载彩页</a>
                    </div>      
                </div>         
            </b-col>
        </b-row>
        <b-row class="text-left">
            <b-col md="6">
                <div class="container">
                <div class="h1" style="color:rgb(2, 65, 139);letter-spacing:0.3rem;font-weight: bold;margin-bottom: 1rem;">产品特点</div>
                <div style="width:100%;margin:0 auto;" class="text-left" v-for="f in features" :key="f.title">
                  <div class="feature-title py-1" v-text="f.title"></div>
                  <div class="feature-desc p-2" v-text="f.desc"></div>
                </div>
                </div>
            </b-col>
            <b-col md="6">
                <div class="container">
                    <div class="h1" style="color:rgb(2, 65, 139);letter-spacing:0.3rem;font-weight: bold;margin-bottom:2rem;">预期用途</div>
                    <p class="body-text" style="letter-spacing:0.1rem;line-height: 2.5rem;">STM FM 01医用流量计适用于体外循环手术，主要用于手术过程中测量体外循环管道内的血液流量。该产品可在医院手术室、导管室、急诊部及重症监护室等科室使用，为医疗专业人员提供准确的流量数据。</p>
                </div>                
            </b-col>
        </b-row>

        <div class="section-tech-param my-5 py-5">
          <b-row class="my-3">
              <b-col class="text-left">
                  <div class="h1" style="color:rgb(2, 65, 139);letter-spacing:0.3rem;font-weight: bold;">技术参数</div>
              </b-col>
          </b-row>
          <b-row>
              <b-col md="1">
              </b-col>
              <b-col md="10">
                   <div class="p-2">
                    <table class="table normal-font">
                    <thead class="table-light">
                        <tr style="background-color: #eee;">
                            <th class="text-right">参数</th>
                            <th class="text-left" colspan="2">测量范围</th>
                            <th class="text-left">测量精度</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="text-right bd-right" rowspan="2" style="vertical-align: middle;">流量</td>
                            <td class="text-left bd-right">3/8"</td>
                            <td class="text-left bd-right">-10~10LPM</td>
                            <td class="text-left">±0.01L/min或者±5%，两者取绝对值大者</td>
                        </tr>
                        <tr>
                            <td class="text-left bd-right">1/4"</td>
                            <td class="text-left bd-right">-4~4LPM</td>
                            <td class="text-left">±0.01L/min或者±5%，两者取绝对值大者</td>
                        </tr>
                        <tr>
                            <td class="text-right bd-right">气泡报警范围</td>
                            <td class="text-left bd-right" colspan="2">单个≥0.5ml</td>
                            <td class="text-left">/</td>
                        </tr>
                        <tr>
                            <td class="text-right bd-right bd-bottom">电池余量</td>
                            <td class="text-left bd-right bd-bottom" colspan="2">0~100%</td>
                            <td class="text-left bd-bottom">±10%</td>
                        </tr>
                    </tbody>
                    </table>
                   </div>
              </b-col>
              <b-col md="1">
              </b-col>
          </b-row>
        </div>


    </b-container>
</template>
<style>
  .key-word{
      font-size:3.5rem;
  }

  .title-text{
      font-size:2rem!important;letter-spacing:0.3rem; font-weight: bold!important;
  }

  .body-text{
      font-size:1.2rem;
  }

  .instruction-text{
    font-size:0.85rem;
    color:#999;
    margin:0;
  }

  .table-cell{
    border:1px solid white;
    margin:0;
    padding:0.5rem;
    font-size:1.2rem;
  }

  .feature-title{
    color:white;
    background-color:#2A609E;
    width:30%;
    border-radius:1rem;
    text-align: center;
    font-size:1.2rem;
    margin-bottom:0.5rem;
  }

  .feature-desc{    
    border-top:1px dashed #ccc;
    margin-bottom:1rem;
  }
  .feature-desc::before {
        content: '\2022'; /* Unicode字符代表实心圆点 */
        display: inline-block; 
        transform: translateX(-1em); /* 使用transform属性向左移动一个字符宽度，从而实现缩进 */
    }

    .bd-right{
        border-right:1px solid #ccc;
    }

    .bd-bottom{
        border-bottom: 1px solid #ccc;
    }

    .normal-font{
        font-size:1.2rem;
    }
</style>
<script>
import Breadcrumb from "@/components/common/Breadcrumb";
export default {
  name: "FM01",
  components:{Breadcrumb},
  data:function(){
    return {
      items:[
        {}
      ],
      features:[
        {'title':'双通道匹配', "desc":'配备双通道，适应不同管径(3/8"或1/4")，确保多样流量监测需求。'},
        {'title':'气泡监测报警', "desc":"能准确监测0.5ml及以上气泡，实时报警, 确保患者生命安全"},
        {'title':'高精准监测', "desc":"提供精准可靠的流量数据，测量误差小"},
      ]
    };
  }
}
</script>