<template>
  <div class="recruiting container mb-3">
    <Breadcrumb title="招贤纳士"></Breadcrumb>
<!--    <b-card title="加入赛腾">-->
<!--      <b-card-text>-->
<!--        诚挚欢迎<span class="specialist"> 临床，生物，电子，机械 </span>方向的有识之士加入赛腾，请把最新的个人简历发送至以下邮箱，我们会以最快的速度给您反馈。-->
<!--      </b-card-text>-->
<!--      <a href="mailto:hr@st-med.com" class="card-link">hr@st-med.com</a>-->
<!--    </b-card>-->
    <b-list-group>
      <b-list-group-item target="_blank" :href="job.job_href" class="pl-5 py-4 flex-column align-items-start text-left" v-for="job in jobList" :key="job.job_id">
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">{{job.job_name}} </h5>
          <small class="text-muted">点击查看</small>
        </div>
      </b-list-group-item>
    </b-list-group>

  </div>
</template>

<script>
import Breadcrumb from "@/components/common/Breadcrumb";
import { jobList } from "@/constant/index";

export default {
name: "Recruiting",
  components: {Breadcrumb},
  data:function(){
    return {
      jobList
    }
  }
}
</script>

<style scoped>

.jobwelf{
  font-size:0.9rem;
  color:#999;
  display: none;
}

/*.recruiting .specialist{*/
/*  font-size:1.2rem;*/
/*  font-weight: bold;*/
/*  color: rgb(2, 65, 139);*/
/*}*/

/*.recruiting .card{*/
/*  text-align: justify;*/
/*  max-width:500px;*/
/*  min-width:350px;*/
/*  margin: 0 auto;*/
/*  color:rgb(2, 65, 139);*/
/*  background-color: #e9ecef;*/
/*}*/

/*@media (max-width: 1024px){*/
/*  .recruiting .card{*/
/*    padding:1rem;*/
/*  }*/

/*  .recruiting .card-title{*/
/*    font-size:2.5rem;*/
/*  }*/
/*}*/

/*@media (min-width: 1024px){*/
/*  .recruiting .card{*/
/*    padding:3rem;*/
/*  }*/

/*  .recruiting .card-title{*/
/*    font-size:2.5rem;*/
/*  }*/
/*}*/
</style>