<template>
    <div class="environment">
        <h1 class="my-4 text-left" style="color:rgb(2, 65, 139);">环境与设施</h1>
        <b-container>
            <b-row>
                <b-col lg="8" class="d-flex flex-column p-0">
                    <b-row class="flex-grow-1">
                        <b-col>
                            <p class="text-justify text-body">
                                <span class="text-title">占地面积：</span>占地面积5300平方米，建有洁净车间1200平方米，有源产品生产车间1500平方米，可以满足首台套产品的生产需要。
                            </p>
                        </b-col>
                    </b-row>
                    <b-row class="flex-grow-1">
                        <b-col>
                            <p class="text-justify text-body">
                                <span class="text-title">研发设备：</span>拥有TOC分析仪、数采主机、水冷式冰水机、水循环模温机、气相色谱仪、立式鼓风干燥箱、三机一体除湿机等研发设备，可以满足产品的研发生产需要。
                            </p>
                        </b-col>
                    </b-row>
                    <b-row class="flex-grow-1">
                        <b-col class="d-flex flex-column">
                            <div class="flex-grow-1">
                                <b-img :src="require('@/assets/env/env-production-warehouse.jpg')"></b-img>
                            </div>
                            <div class="flex-grow-1">
                                <span class="text-title">生产车间</span>
                            </div>
                        </b-col>
                        <b-col class="d-flex flex-column">
                            <div class="flex-grow-1">
                                <b-img :src="require('@/assets/env/env-cleansing-warehouse.jpg')"></b-img>
                            </div>
                            <div class="flex-grow-1">
                                <span class="text-title">洁净车间</span>
                            </div>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col lg="4">
                    <b-img :src="require('@/assets/env/env-laboratory.jpg')"></b-img>
                </b-col>
            </b-row>
        </b-container>
    </div>
  </template>
  
  <script>
  export default {
  name: "Environment",
    data:function () {
      return {
      }
    }
  }
  </script>
  
  <style scoped>
  .text-title {
    font-weight: bold;
    color:#007AD1;
  }
  
  .environment .text-body{
    color: #504366!important;
  }

  .environment .text-title, .environment .text-body{
    line-height:1.5em;
  }

  @media (min-width: 1200px) {
    .environment{
      width:80%;
      margin: 0 auto;
      padding:2rem 0;
    }
  }
  </style>