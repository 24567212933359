<template>
  <div class="contact-us container">
    <Breadcrumb title="联系我们"></Breadcrumb>
    <div class="view-contact">
      <b-row>
        <b-col lg="8" class="mb-2">
          <b-card no-body border-variant="info" align="center">
            <div class="amap-wrapper">
              <el-amap class="amap-box" :vid="'amap-vue'" :center='[120.761911,31.283002]' :zoom='15'>
                <el-amap-marker vid="component-marker" :position="[120.761911,31.283002]"  ></el-amap-marker>
              </el-amap>
            </div>
          </b-card>
        </b-col>
        <b-col lg="4">
          <b-card no-body header="联系方式" align="left" style='border:none;color:#222;' class="contact-detail">
            <b-card-body class="pt-2 pb-2" v-for="label in Object.keys(contactInfo[0])" :key="label">
              <b-card-title style="font-size:1rem;font-weight:bold;">{{label}}</b-card-title>
              <b-card-text style="font-size:0.8rem;">
                {{contactInfo[0][label]}}
              </b-card-text>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/common/Breadcrumb";
export default {
name: "Contact",
  components: {Breadcrumb},
  data(){
    return {
      contactInfo:[{
        '办公地址': '江苏省苏州市苏州工业园区东富路9号东景工业坊36幢, 邮编(215123)',
        '电话': '+86-0512-6788-6680',
        '传真': '+86-0512-6788-6690',
        '电子邮件': 'contact@st-med.com'
      }]
    }
  }
}
</script>

<style scoped>

@media (max-width: 1024px) {
  .view-contact{
    padding-top:1rem;
  }
}

@media (min-width: 1024px) {
  .view-contact{
    padding-top:3rem;
  }
}

.view-contact{
  padding-bottom:1rem;
  color: rgb(2, 65, 139)!important;
}


.amap-wrapper{
  height:300px;
  padding:3px;
  border:1px solid #ddd;
  border-radius:5px;
}

.card-header{
  border: 1px solid rgba(0, 0, 0, 0.125);
}
</style>