<template>
    <div class="culture">
        <b-jumbotron lead="企业文化">
            <b-row>
        <!-- 左侧文本列 -->
        <b-col lg="4" class="p-3">
            <p class="jumbotron-title jumbotron-title1"><b-avatar variant="light" class="mr-2" :src="require('@/assets/culture/icon-patient.png')"></b-avatar>患者第一</p>
          
        </b-col>
        <b-col lg="4" class="p-3">
            <p class="jumbotron-title jumbotron-title2"><b-avatar variant="light" class="mr-2" :src="require('@/assets/culture/icon-product.png')"></b-avatar>产品卓越</p>            
        </b-col>
        
        <!-- 右侧图片列 -->
        <b-col lg="4" class="p-3">
            <p class="jumbotron-title jumbotron-title3"><b-avatar variant="light" class="mr-2" :src="require('@/assets/culture/icon-partnership.png')"></b-avatar>合众共赢</p>
        </b-col>
      </b-row>
      <b-row>
          <img src="@/assets/culture/culture-new-image.jpg" alt="Your Image" class="img-fluid">
        </b-row>
          
  <!--        <b-button variant="outline-primary" to="/products">查看更多</b-button>-->
        </b-jumbotron>
    </div>
  </template>
  
  <script>
  export default {
  name: "CultureNew",
    data:function () {
      return {
      }
    }
  }
  </script>
  
  <style scoped>
  .culture .jumbotron{
    background-color: transparent;
    padding:0rem 0;
  }
  .culture{
    margin:1rem auto;
    text-align: left;
    color:rgb(2, 65, 139);
  
  }
  
  .culture .lead{
    font-size:2.5rem;
  }
  
  .culture .jumbotron-text{
    color:#504366;
  }

  .culture .jumbotron-title {
    text-align: left;
    font-size:24px;
    padding:0.5rem;
  }

  .culture .jumbotron-title1{
    color:#02418B;
  }

  .culture .jumbotron-title2{
    color:#0B59B1;
  }

  .culture .jumbotron-title3{
    color:#248DBB;
  }

  @media (min-width: 1200px) {
    .culture{
      width:80%;
    }
  }
  </style>