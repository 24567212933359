<template>
  <div class="intro">
      <b-jumbotron lead="企业愿景">
        <p class="jumbotron-text">“赛腾”即Science & Technology，科学与技术。</p>
        <p class="jumbotron-text">赛腾希望通过探索、研究、发明、创造，不断推进为生命提供支持的科学技术向前发展，使人体有机会从急性创伤中恢复进而重获新生，或能延缓器官不可避免的衰竭进而大幅提升生命质量。</p>
<!--        <b-button variant="outline-primary" to="/products">查看更多</b-button>-->
      </b-jumbotron>
  </div>
</template>

<script>
export default {
name: "Intro",
  data:function () {
    return {
    }
  }
}
</script>

<style scoped>
.intro{
  margin:0rem auto;
  text-align: left;
  color:rgb(2, 65, 139);

}

.intro .lead{
  font-size:2.5rem;
}

.intro .jumbotron{
    background-color: transparent;
    padding:2.5rem 0;
  }

.intro .jumbotron-text{
  color:#504366;
}

@media (min-width: 1200px) {
  .intro{
    width:80%;
  }
}
</style>