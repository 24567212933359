<template>
  <div class="home">
    <Jumbotron></Jumbotron>
    <MenuDisplay></MenuDisplay>
  </div>
</template>

<script>
// @ is an alias to /src
import Jumbotron from "../components/home/Jumbotron.vue";
import MenuDisplay from "@/components/home/MenuDisplay";
export default {
  name: 'Home',
  components: {
    MenuDisplay, Jumbotron
  }
}
</script>
<style scoped>

.home{
  min-height:400px;
}
</style>