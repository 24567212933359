<template>
  <div :class="`product-line bg-0`">
    <b-row>
      <b-col lg="6">
        <b-img :src="product.imgSrc" fluid alt="placeholder"></b-img>
      </b-col>
      <b-col class="pt-lg-5 text-lg-left p-3" lg="6">
        <div style="font-size:1.5rem" class="px-3" v-html="getTitle"></div>
        <div style="font-size:1.25rem;color:#aaa;" class="px-3" v-if="product.isComing">即将发布</div>
        <div class="px-3" v-else>
            <div class="product-regno py-2" style="color:#bbb;font-size:0.9rem;">
              {{product.regNo}}
            </div>
            <div class="product-summary text-justify" style="color:#999;line-height:1.5rem;">
              {{product.summary}}
            </div>
            <div class="product-link text-right py-3">
                <a :href="product.href" style="font-size:0.9rem;">&gt;&gt;了解更多</a>
            </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "ProductLine",
  props:["product"],
  data:function(){
    return {
    };
  },
  computed:{
    getTitle:function(){
      return this.product.title.replace("®", "<sup>®</sup>");
    }
  }
}
</script>

<style scoped>
.bg-0{
  background: linear-gradient(#fff, #fff 60%, #eeeeee);
}
</style>